/** @define Icon */

:root {
  --Icon-blueColor: #79cdc6;
  --Icon-featureYellow: #fede91;
  --Icon-rssColor: #e06321;
  --Icon-glyphColor: #000;
  --Icon-invertedGlyphColor: #fff;
  --Icon-youtubeRed: #ff0000;
  --Icon-amazonBlack: #101010;
  --Icon-bandcampBlue: #1DA0C3;
  --Icon-tuneInGreen: #14d8cc;
}

.Icon--website .Icon-glyph {
  background-image: url('../assets/icons/social-website.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--twitter .Icon-glyph {
  background-image: url('../assets/icons/social-twitter.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--facebook .Icon-glyph {
  background-image: url('../assets/icons/social-facebook.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--instagram .Icon-glyph {
  background-image: url('../assets/icons/social-instagram.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--mastodon .Icon-glyph {
  background-image: url('../assets/icons/social-mastodon.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--youtube .Icon-glyph {
  background-image: url('../assets/icons/social-youtube.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--bandcamp .Icon-glyph {
  background-image: url('../assets/icons/social-bandcamp.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--applemusic .Icon-glyph {
  background-image: url('../assets/icons/social-applemusic.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--spotify .Icon-glyph {
  background-image: url('../assets/icons/social-spotify.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--lastfm .Icon-glyph {
  background-image: url('../assets/icons/social-lastfm.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--mixcloud .Icon-glyph {
  background-image: url('../assets/icons/social-mixcloud.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--soundcloud .Icon-glyph {
  background-image: url('../assets/icons/social-soundcloud.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--itunes .Icon-glyph {
  background-image: url('../assets/icons/social-podcasts.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--google .Icon-glyph {
  background-image: url('../assets/icons/social-googleplay.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--playerfm .Icon-glyph {
  background-image: url('../assets/icons/social-playerfm.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--tunein .Icon-glyph {
  background-image: url('../assets/icons/social-tunein.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--breaker .Icon-glyph {
  background-image: url('../assets/icons/social-breaker.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--pocketcasts .Icon-glyph {
  background-image: url('../assets/icons/social-pocketcasts.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--googlepodcast .Icon-glyph {
  background: url('../assets/icons/podcast-google.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--castro .Icon-glyph {
  background-image: url('../assets/icons/podcast-castro.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--overcast .Icon-glyph {
  background-image: url('../assets/icons/podcast-overcast.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--stitcher .Icon-glyph {
  background-image: url('../assets/icons/podcast-stitcher.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--amazon .Icon-glyph {
  background-image: url('../assets/icons/podcast-amazon.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--googleCalendar .Icon-glyph {
  background-image: url('../assets/icons/calendar-google.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--outlook .Icon-glyph {
  background-image: url('../assets/icons/calendar-outlook.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--ical .Icon-glyph {
  background-image: url('../assets/icons/calendar-ical.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--rss .Icon-glyph {
  background-image: url('../assets/icons/social-rss.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--rssOrange .Icon-glyph {
  background-image: url('../assets/icons/social-rss.svg' param(--color var(--Icon-rssColor)));
}

.Icon--feedly .Icon-glyph {
  background-image: url('../assets/icons/feed-feedly.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--feedbin .Icon-glyph {
  background-image: url('../assets/icons/feed-feedbin.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--newsblur .Icon-glyph {
  background-image: url('../assets/icons/feed-newsblur.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--oldreader .Icon-glyph {
  background-image: url('../assets/icons/feed-oldreader.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--email .Icon-glyph {
  background-image: url('../assets/icons/social-email.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--share .Icon-glyph {
  background-image: url('../assets/icons/social-share.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--bluesky .Icon-glyph {
  background-image: url('../assets/icons/social-bluesky.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--tiktok .Icon-glyph {
  background-image: url('../assets/icons/social-tiktok.svg' param(--color var(--Icon-glyphColor)));
}

/* Custom hover/focus states for full colour encircled icons */
.Icon--encircled.Icon--youtube:link:hover .Icon-glyph,
.Icon--encircled.Icon--youtube:link:focus .Icon-glyph {
  filter: none;
  background-image: url('../assets/icons/social-youtube.svg' param(--color var(--Icon-youtubeRed)));
}

.Icon--encircled.Icon--tiktok:link:hover .Icon-glyph,
.Icon--encircled.Icon--tiktok:link:focus .Icon-glyph {
  filter: none;
  background-image: url('../assets/icons/social-tiktok-color.svg');
}

.Icon--encircled.Icon--tunein:link:hover .Icon-glyph,
.Icon--encircled.Icon--tunein:link:focus .Icon-glyph {
  /* TuneIn uses the TuneIn brand colour on hover */
  filter: none;
  background-image: url('../assets/icons/social-tunein.svg' param(--color var(--Icon-tuneInGreen)));
}

.Icon--encircled.Icon--googlepodcast:link:hover .Icon-glyph,
.Icon--encircled.Icon--googlepodcast:link:focus .Icon-glyph {
  filter: none;
  background-image: url('../assets/icons/podcast-google-color.svg');
}

.Icon--encircled.Icon--stitcher:link:hover .Icon-glyph,
.Icon--encircled.Icon--stitcher:link:focus .Icon-glyph {
  filter: none;
  background-image: url('../assets/icons/podcast-stitcher-color.svg');
}

/* Player Icons */

.Icon--directionArrow .Icon-glyph {
  background-image: url('../assets/icons/direction-arrow.svg');
}

.Icon--playerPlay .Icon-glyph {
  background-image: url('../assets/icons/player-play.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--playerPause .Icon-glyph {
  background-image: url('../assets/icons/player-pause.svg' param(--color var(--Icon-glyphColor)));
}

.Icon--playerPlayWhite .Icon-glyph {
  background-image: url('../assets/icons/player-play.svg' param(--color #fff));
}

.Icon--playerPauseWhite .Icon-glyph {
  background-image: url('../assets/icons/player-pause.svg' param(--color #fff));
}

/* These are used over the brand-blue menu, so should always be black/white rather than themed
 * TODO: Need a better way to expose that
 * TODO: Using font sprites might actually be better for all these
 */
.Icon--playerSkipBackwards .Icon-glyph,
.Icon--playerSkipForwards .Icon-glyph {
  background-image: url('../assets/icons/player-skip.svg' param(--color #000));
}

.Icon--playerSkipStart .Icon-glyph,
.Icon--playerSkipEnd .Icon-glyph {
  background-image: url('../assets/icons/player-end.svg' param(--color #000));
}

.Icon--playerSkipForwards .Icon-glyph,
.Icon--playerSkipEnd .Icon-glyph {
  transform: scale(-1, 1); /* flip horizontal */
}

.Icon--playerSkipBackwardsWhite .Icon-glyph,
.Icon--playerSkipForwardsWhite .Icon-glyph {
  background-image: url('../assets/icons/player-skip.svg' param(--color #fff));
}

.Icon--playerSkipStartWhite .Icon-glyph,
.Icon--playerSkipEndWhite .Icon-glyph {
  background-image: url('../assets/icons/player-end.svg' param(--color #fff));
}

.Icon--playerSkipForwardsWhite .Icon-glyph,
.Icon--playerSkipEndWhite .Icon-glyph {
  transform: scale(-1, 1); /* flip horizontal */
}

/* Always used on black background */
.Icon--volume .Icon-glyph {
  background-image: url('../assets/icons/player-volume.svg' param(--color #fff));
}

/* Icons for shows pages */
.Icon--podcast .Icon-glyph {
  background-image: url('../assets/icons/social-podcasts.svg' param(--color var(--Icon-blueColor)));
}

.Icon--calendar .Icon-glyph {
  background-image: url('../assets/icons/calendar.svg');
}

.Icon--reminder .Icon-glyph {
  background-image: url('../assets/icons/reminder.svg' param(--color var(--Icon-blueColor)));
}

.Icon--feature .Icon-glyph {
  background-image: url('../assets/icons/star.svg' param(--color var(--Icon-featureYellow)));
}

.Icon--spinnerWhite .Icon-glyph {
  background-image: url('../assets/icons/spinner.svg' param(--color var(--Icon-invertedGlyphColor)));
}
